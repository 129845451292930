import React from "react"
import { css } from "@emotion/core"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Container from "../components/container"
import Breadcrumbs from "../components/breadcrumbs"
import Button from "../components/button"
import { Colors } from "../components/constants"
import ContactForm from "../components/contact-form"

const IndexPage = () => (
  <Layout>
    <SEO title="How Notary Works - JustNotaries" />
    <Breadcrumbs
      crumbs={[
        {
          label: `Home`,
          url: `/`,
        },
        { label: `Notary Services` },
      ]}
    />
    <Container
      element="section"
      id="services"
      css={css`
        padding-top: 60px;
        padding-bottom: 60px;
      `}
    >
      <h1
        css={css`
          padding-bottom: 0.5em;
        `}
      >
        Notary Services
      </h1>
      <ul
        css={css`
          margin: 0;
          padding-left: 20px;

          @media (min-width: 690px) {
            columns: 2;
          }
          @media (min-width: 960px) {
            columns: 3;
          }
        `}
      >
        <li>Power of Attorney</li>
        <li>Trust Agreement</li>
        <li>Will</li>
        <li>Compliance Agreement</li>
        <li>Trust</li>
        <li>Pre-Marital Agreement</li>
        <li>Living Trust</li>
        <li>Domestic Partnerships</li>
        <li>Deeds</li>
        <li>Dissolution of Marriage</li>
        <li>Trust Deed</li>
        <li>Affidavits</li>
        <li>Grant Deed</li>
        <li>Proof of Loss Claim/ Statement</li>
        <li>Warranty Deed</li>
        <li>Lien Releases</li>
        <li>Quitclaim Deed</li>
        <li>Judgment</li>
        <li>Trust Transfer Deed</li>
        <li>Stipulation of Judgment</li>
        <li>Advanced Medical Directives</li>
        <li>Permission For Minor To Travel</li>
        <li>DMV Signature Verification</li>
        <li>Homesteads</li>
        <li>Personal Statements</li>
        <li>Affidavit Death of Joint Tenant</li>
        <li>Bill of Sale</li>
        <li>...More</li>
      </ul>
    </Container>
    <div
      element="section"
      id="services"
      css={css`
        text-align: center;
        padding-top: 60px;
        padding-bottom: 60px;
        background: ${Colors.grey1};

        a {
          margin: 0 5px;
        }
      `}
    >
      <Button element="a" href="/how-it-works" outline={true}>
        How it Works
      </Button>
      <Button
        element="a"
        target="_blank"
        rel="noopener noreferrer"
        href="https://calendly.com/loganstellway/30min?back=1&month=2020-02"
      >
        Schedule a Signing
      </Button>
    </div>
    <Container
      element="section"
      id="services"
      css={css`
        padding-top: 60px;
        padding-bottom: 60px;
      `}
    >
      <h2>Questions</h2>
      <p>
        If you don't see a service listed here or have any other questions,
        please feel free to reach out.
      </p>

      <ContactForm />
    </Container>
  </Layout>
)

export default IndexPage
